<template>
  <div class="vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="px-8 pt-8 login-tabs-container">

              <div class="vx-card__title mb-4" v-if="!completed">
                <h4 class="mb-4">¿Olvidaste tu contraseña?</h4>
                <p>Escribe tu alias o correo electrónico para recuperar tu contraseña.</p>
              </div>

              <div class="vx-card__title mb-4" v-if="completed">
                <h4 class="mb-4 text-success">Solicitud completada.</h4>
                <p>En breve recibirás un correo electrónico con las instrucciones para completar la recuperación.
                  Revísalo y sigue el proceso.
                </p>
              </div>

              <vs-alert v-if="errorMssg" class="h-full mb-5" color="danger">
                <span>{{ errorMssg }}</span>
                <span v-if="canRequestNewActivationMail">
                  Puedes solicitar un nuevo correo de activación dando clic
                  <router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="goToRequestActivationEmail">aquí.</router-link>
                </span>
              </vs-alert>

              <vx-input-group
                class="w-full mb-4" v-if="!completed">
                <vs-input
                  :class="emailInputClass"
                  placeholder="Correo o alias"
                  v-validate="'required'"
                  autocomplete="email"
                  data-vv-name="email"
                  type="username"
                  v-on:keyup.enter="requestReset"
                  v-model.lazy="email" />

                <template slot="append">
                  <div class="append-text btn-addon" >
                    <vs-button color="dark" @click="toggleEmailInputType()" icon-pack="feather" :icon="emailInputIcon"></vs-button>
                  </div>
                </template>
              </vx-input-group>

              <vs-button v-if="!completed" :disabled="email == null || $isLoading" @click="requestReset" color="primary" class="mr-5 mt-4">Recuperar contraseña</vs-button>

              <div class="mt-base mb-6">
                <div class="text-dark">
                  ¿Deseas volver a la página de inicio? <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="backToLogin">Da clic aquí.</router-link></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
const errorMssgDef = "Ha ocurrido un error, intente más tarde."
const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";
export default {
  name: "PasswordResetPage",
  data() {
    return {
      email: null,
      name: null,
      password: null,
      errorMssg: null,
      showFormPrompt: null,
      emailInputPassMode: true,
      passInputPassMode: true,
      isMounted: false,
      completed: false,
      canRequestNewActivationMail: false,
    }
  },
  async mounted(){

  },
  computed: {
    emailInputClass(){
      return this.emailInputPassMode ? "input-font-password" : "input-font-regular";
    },
    emailInputIcon(){
      return this.emailInputPassMode ? iconEye : iconEyeOff;
    },
    passInputType(){
      return this.passInputPassMode ? "password" : textType;
    },
    passInputIcon(){
      return this.passInputPassMode ? iconEye : iconEyeOff;
    },
  },
  created() {
    this.ACCOUNT_NOT_ACTIVATED = "account_not_activated";
  },
  methods: {
    async requestReset(){
      this.showLoading(true);
      this.errorMssg = false;
      try {
        const payload = { email: this.email};
        await this.publicApiPost('/api/inversionista/reset', payload);
        this.completed = true;
      }
      catch(error) {
        if(error.response && error.response.data && error.response.data.message){
          this.errorMssg = error.response.data.message;

          if(error.response.data.extra && error.response.data.extra === this.ACCOUNT_NOT_ACTIVATED){
            this.canRequestNewActivationMail = true;
          }
        }
        else {
          this.errorMssg = errorMssgDef;
        }
      }
      this.showLoading(false);
    },
    toggleEmailInputType(){
      this.emailInputPassMode = !this.emailInputPassMode;
    },
    async goToRequestActivationEmail(){
      await this.$router.push({name: 'requestActivationEmail'});
    },
    async backToLogin(){
      await this.$router.replace({name: 'login'});
    }
  }
}
</script>

<style scoped>

</style>